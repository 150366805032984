/** @format */

@tailwind base;
@tailwind components;
@tailwind utilities;
/* global.css o styles.css */
@import "react-day-picker/dist/style.css";

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground overflow-hidden;
  }
}

.agent-visualizer {
  background-color: white;
  height: 100%; /* Ensure it takes the full height of the parent container */
  width: 100%; /* Ensure the width of the container is 100% */
  gap: 20px; /* Optional: add some space between the bars */
  display: flex;
  flex-direction: row; /* Align the bars horizontally */
  justify-content: center; /* Distribute bars evenly */
  align-items: center; /* Align the bars at the bottom of the container */
}

/* Default styles for the bars */
.agent-visualizer span {
  width: 72px; /* Set the width of each bar */
  border: 1px solid; /* Set the border color of the bars */
  border-radius: 99px; /* Set the border radius of the bars */
  background-color: black; /* Set the background color of the bars */
  margin: 2px 0; /* Optional: space out the bars */
}

/* Styles when the bar is highlighted */
.agent-visualizer span[data-lk-highlighted="true"] {
  background-color: white; /* Change the background color when highlighted */
}

.lk-agent-control-bar {
  @apply h-12 w-12 cursor-pointer mr-2 rounded-2xl flex flex-row justify-center items-center hover:bg-[#6b221a] hover:text-white bg-[white] border-[#6b221a];
}

.lk-disconnect-button {
  @apply h-12 w-12 cursor-pointer rounded-2xl flex flex-row justify-center items-center hover:bg-[#6b221a] hover:text-white bg-[red] border-[#6b221a];
}
